import components from './components'
import {createApp} from 'vue'
import Lightbox from 'vue-easy-lightbox'
import { setupCalendar } from 'v-calendar';

// Import our CSS
import '@/css/app.css'

// App main
const main = async () => {

    const app = createApp({});
    //pull in all the things
    for (const [Name, Component] of Object.entries(components.components)) {
        app.component(Name, Component)
    }
    app.use(Lightbox)
    app.use(setupCalendar, {})
    // Mount the app
    return app.mount('#component-container')
};

// Execute async function
main().then(() => {
    console.log('vue loaded')
});

// Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
if (import.meta.hot) {
    import.meta.hot.accept(() => {
        console.log("HMR")
    })
}
