import {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    Dialog,
    DialogPanel,
    DialogTitle,
    TransitionChild,
    TransitionRoot
} from '@headlessui/vue'
import {
    BellIcon,
    Bars3Icon,
    XMarkIcon,
    ChevronDownIcon,
    CheckIcon,
    HomeIcon,
    UserCircleIcon,
    NewspaperIcon
} from '@heroicons/vue/24/outline'
import WagIconHelper from './WagIconHelper.vue'
import WagSwiper from './WagSwiper.vue'
import WagNavbar from './WagNavbar.vue'
import WagModal from './WagModal.vue'
import ScrollTo from './ScrollTo.vue'
import WagFacebookFeed from './WagFacebookFeed.vue'
import WagVueCalendar from "./WagVueCalendar.vue";

const components = {
    'wag-vue-calendar': WagVueCalendar,
    'wag-modal': WagModal,
    'scroll-to': ScrollTo,
    'wag-icon-helper': WagIconHelper,
    'disclosure': Disclosure,
    'disclosure-button': DisclosureButton,
    'disclosure-panel': DisclosurePanel,
    'vue-menu': Menu,
    'menu-button': MenuButton,
    'menu-item': MenuItem,
    'menu-items': MenuItems,
    'bell-icon': BellIcon,
    'menu-icon': Bars3Icon,
    'x-icon': XMarkIcon,
    'check-icon': CheckIcon,
    'chevron-down-icon': ChevronDownIcon,
    'wag-swiper': WagSwiper,
    'dialog': Dialog,
    'dialog-panel': DialogPanel,
    'dialog-title': DialogTitle,
    'transition-child': TransitionChild,
    'transition-root': TransitionRoot,
    'home-icon': HomeIcon,
    'user-circle-icon': UserCircleIcon,
    'newspaper-icon': NewspaperIcon,
    'wag-navbar': WagNavbar,
    'wag-facebook-feed': WagFacebookFeed
}

export default {
    components: components
}